<template>
  <div class="board">
    <template v-if="Object.keys(betList).length > 0">
      <template v-for="(item) in betList" :key="item[0].betIdx + item[0].betId + item[0].newLineID">
        <div class="boardwWrap">
          <ul class="boardw first w1600">
            <!-- <li class="w7">번호</li> -->
            <li class="w13">경기시간</li>
            <li class="w3">종목</li>
            <li class="w13">리그명</li>
            <li class="w13">홈팀</li>
            <li class="w3">스코어</li>
            <li class="w13">원정팀</li>
            <li class="w13">베팅위치</li>
            <li class="w3">배당률</li>
            <!-- <li class="w5">윈루즈</li> -->
            <li class="w3">상태</li>
          </ul>
          <ul class="boardw w1600">
            <!-- <li class="w7">{{ item[0].betIdx }}</li> -->
            <li class="multi w13">
              <span class="combi" v-for="data in item" :key="data.newLineID">
                {{ data.eventDate.replace('T', ' ') }}
              </span>
            </li>
            <li class="multi w3">
              <span v-for="data in item" :key="data.betIdx + data.betId + data.newLineID">
                {{ SPORT_BRANCH_LIST[data.branchID] }}
              </span>
            </li>
            <li class="w13">{{ item[0].leagueName }}</li>
            <li class="multi w13">
              <span v-for="data in item" :key="data.betIdx + data.betId + data.newLineID">
                {{ data.homeTeam }}
              </span>
            </li>
            <li class="multi w3">
              <span v-for="data in item" :key="data.betIdx + data.betId + data.newLineID">
                {{ data.score }}
              </span>
            </li>
            <li class="multi w13">
              <span v-for="data in item" :key="data.betIdx + data.betId + data.newLineID">
                {{ data.awayTeam }}
              </span>
            </li>
            <li class=" multi w13">
              <span v-for="data in item" :key="data.betIdx + data.betId + data.newLineID">
                {{ data.yourBet }}
              </span>
            </li>
            <li class="multi w3">
              <span v-for="data in item" :key="data.newLineID" :class="{ 'ft-01' : data.lineOdds > 0 && data.lineOdds < 1.99, 'ft-02' : data.lineOdds > 2 && data.lineOdds < 4.99, 'ft-03' : data.lineOdds > 5}">
                {{ data.lineOdds }}
              </span>
            </li>
            <!-- <li class="w5">{{ thousand(Number(item[0].betWinAmt) - Number(item[0].betAmt)) }}</li> 윈루즈-->
            <li class="multi w3">
              <span v-for="data in item" :key="data.newLineID">
                {{ data.status }}
              </span>
            </li>
          </ul>
          <ul class="boardw w1600">
            <li class="commonWrap">
              <ul>
                <li class="">
                  <em>베팅시간</em><span>{{ item[0].betDate.replace('T', ' ') }}</span>
                </li>
                <li class="">
                  <template v-if="item[0].betTypeName === '단폴'">
                    <span class="blue-bg" style="color: white;">{{ item[0].betTypeName }}</span>
                  </template>
                  <template v-else>
                    <span class="purple-bg" style="color: white;">{{ item[0].betTypeName }}</span>
                  </template>
                </li>
              </ul>
              <ul>
                <li>
                  <em>베팅금</em><span>{{ thousand(item[0].betAmt) }}</span>
                </li>
                <li>
                  <em>통합 배당률</em><span>{{ calOdds(item) }}</span>
                </li>
                <li>
                  <em>예상 당첨금</em><span>{{ thousand(exCalOdds(item)) }}</span>
                </li>
                <li>
                  <em>당첨금</em><span>{{ thousand(item[0].betWinAmt) }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </template>
    </template>
    <template v-else>
      <ul class="boardw w1600">
        <li>내역이 없습니다.</li>
      </ul>
    </template>
  </div>
</template>
<script>
import { SPORT_BRANCH_LIST } from '@/libs/constants'

export default {
  name: 'SportBetTable',
  computed: {
    SPORT_BRANCH_LIST () {
      return SPORT_BRANCH_LIST
    }
  },
  props: [
    'list'
  ],
  data () {
    return {
      betList: {}
    }
  },
  watch: {
    list () {
      if (this.list.length > 0) {
        this.normalize()
      } else {
        this.betList = {}
      }
    }
  },
  methods: {
    calOdds (item) {
      const totalodd = item.reduce((accumulator, data) => {
        return accumulator * data.lineOdds
      }, 1)

      const roundedTotalOdd = Math.floor(totalodd * 100) / 100

      return roundedTotalOdd.toFixed(2)
    },
    exCalOdds (item) {
      const odds = this.calOdds(item)
      const extotalodd = item.reduce((accumulator, data) => {
        return odds * data.betAmt
      }, 1)
      return extotalodd.toFixed(0)
    },
    normalize () {
      const temp = {}
      this.list.forEach(item => {
        const betIdx = item.betIdx
        const betId = item.betId
        const key = betIdx + betId
        if (!temp[key]) {
          temp[key] = []
        }
        temp[key].push(item)
      })

      console.log(temp)

      this.betList = temp
    }
  },
  created () {
    console.log(this.list)
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped>
.boardwWrap>.boardw.first {
  background: #555;
  color: #fff;
  border: 0;
  border-radius: 10px 10px 0 0;
}
.boardwWrap {margin-bottom: 20px;overflow-x: scroll;}
.boardw {height: auto; min-height: 45px;}
.boardw li {width: auto; white-space: nowrap;}
.boardw li.w3 {width: 3%;}
.boardw li.w5 {width: 5%;}
.boardw li.w7 {width: 7%}
.boardw li.w13 {width: 13%;}
.board li.multi{flex-direction: column;}
.multi > span { display: block; width: 100%; padding: 10px 0; border-bottom: 1px solid #777777; white-space: nowrap;}
.multi > span:last-child {border-bottom: 0;}
.boardw li.commonWrap {display: flex; justify-content: space-between; width: 100%; height: 45px; }
.boardw li.commonWrap ul {display: flex; gap: 20px; width: 50%; height: 100%; height: 100%; }
.boardw li.commonWrap ul:first-child {padding-left: 45px; background: #333333;}
.boardw li.commonWrap ul:last-child { padding-right: 45px;background: #393939; justify-content: end;}
.boardw li.commonWrap ul li {display: flex; gap: 10px; }
.blue-bg{
  padding: 5px 8px;
  text-align: center;
  box-sizing: border-box;
  color:#fff;
  background: #5737e7;
  border-radius: 3px;;
}
.purple-bg{
  padding: 5px 8px;
  text-align: center;
  box-sizing: border-box;
  color:#fff;
  background: #ad0e6c;
  border-radius: 3px;;
}
</style>
